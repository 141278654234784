<template>
  <div class="large" :id="componentId" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
    <h4 class="mb-3"><i class="uil-filter"></i> {{ $t('general.filter_list') }}</h4>
    <div class="filter-container">
      <div class="uk-child-width-1-2@s uk-grid-small" uk-grid>
        <div v-if="!reportAssignment && superAdmin()">
          <h5 class="uk-text-bold mb-2"> {{ $t('users.institution') }}</h5>
          <multiselect v-model="filter.company_id" :allow-empty="false" :multiple="false"
            :options="formattedInstitution" :searchable="true" deselectLabel="" label="name" selectLabel="Seçiniz"
            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" track-by="id"
            @input="changeCompany($event)">
            <template slot="option" slot-scope="option">
              {{ option.option.name }}
            </template>
          </multiselect>
        </div>
        <div v-if="reportAssignment">
          <h5 class="uk-text-bold mb-2"> Atamalar</h5>
          <multiselect v-model="assignment_id" :allow-empty="true" :multiple="true" :options="assignmentList"
            :searchable="true" deselectLabel="" label="name" selectLabel="Seçiniz"
            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" track-by="id">
            <template slot="option" slot-scope="option">
              {{ option.option.name }}
            </template>
          </multiselect>
        </div>
        <div>
          <h5 class="uk-text-bold mb-2">{{ $t('users.mission') }}</h5>
          <!-- <multiple-select-input :model.sync="filter.position_id" :multiple="false" :required="false" :searchable="false"
            :showLabels="false" :values="formattedPosition" :allowEmpty="true" @select="filter.position_id = $event;" /> -->
          <multiselect v-model="filter.position_id" :allow-empty="true" :options="formattedPosition" :searchable="true"
            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" :multiple="true"
            deselectLabel="" label="name" selectLabel="Seçiniz" track-by="id">
            <template slot="option" slot-scope="option">
              {{ option.option.name }}
            </template>
          </multiselect>
        </div>
        <div>
          <h5 class="uk-text-bold mb-2">{{ $t('users.title') }}</h5>
          <!-- <multiple-select-input :model.sync="filter.title_id" :multiple="false" :required="false" :searchable="false"
            :showLabels="false" :values="formattedTitle" :allowEmpty="true" @select="filter.title_id = $event;" /> -->
          <multiselect v-model="filter.title_id" :allow-empty="true" :options="formattedTitle" :searchable="true"
            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" :multiple="true"
            deselectLabel="" label="name" selectLabel="Seçiniz" track-by="id">
            <template slot="option" slot-scope="option">
              {{ option.option.name }}
            </template>
          </multiselect>
        </div>
        <div>
          <h5 class="uk-text-bold mb-2">{{ $t('users.area') }}</h5>
          <!-- <multiple-select-input v-if="!userList" :model.sync="filter.region_id" :multiple="false" :required="false"
            :searchable="false" :showLabels="false" :values="formattedRegion" :allowEmpty="true"
            @select="filter.region_id = $event;" /> -->
          <multiselect v-model="filter.region_id" :allow-empty="true" :options="formattedRegion" :searchable="true"
            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" :multiple="true"
            deselectLabel="" label="name" selectLabel="Seçiniz" track-by="id">
            <template slot="option" slot-scope="option">
              {{ option.option.name }}
            </template>
          </multiselect>
        </div>
        <div>
          <h5 class="uk-text-bold mb-2"> {{ $t('users.department') }}</h5>
          <!-- <multiple-select-input :model.sync="filter.department_id" :multiple="false" :required="false"
            :searchable="false" :showLabels="false" :values="formattedDepartment" :allowEmpty="true"
            @select="filter.department_id = $event;" /> -->
          <multiselect v-model="filter.department_id" :allow-empty="true" :options="formattedDepartment"
            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" :searchable="true"
            :multiple="true" deselectLabel="" label="name" selectLabel="Seçiniz" track-by="id">
            <template slot="option" slot-scope="option">
              {{ option.option.name }}
            </template>
          </multiselect>
        </div>
        <div>
          <h5 class="uk-text-bold mb-2"> {{ $t('users.branch') }} </h5>
          <!-- <multiple-select-input :model.sync="filter.branch_id" :multiple="false" :required="false" :searchable="false"
            :showLabels="false" :values="formattedBranch" :allowEmpty="true" @select="filter.branch_id = $event;" /> -->
          <multiselect v-model="filter.branch_id" :allow-empty="true" :options="formattedBranch" :searchable="true"
            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" :multiple="true"
            deselectLabel="" label="name" selectLabel="Seçiniz" track-by="id">
            <template slot="option" slot-scope="option">
              {{ option.option.name }}
            </template>
          </multiselect>
        </div>
        <div>
          <h5 class="uk-text-bold mb-2"> Tarih </h5>
          <!-- v-model="contentsTimed[selectedEducationId].starts_at" :min-date="setMinimumDate()"
          :max-date="contentsTimed[selectedEducationId].ends_at" -->
          <VueCtkDateTimePicker :format="'YYYY-MM-DD HH:mm:ss'" v-model="selectedDate" :no-button-now="true"
            :range="true" :no-shortcuts="true" />
        </div>



        <div v-if="show_only_unassigned_users">
          <label for="show_only_unassigned_users">
            {{ $t('assignment.show_only_unassigned_users') }}
            <i class="uil-info-circle" uk-tooltip="title: Daha önce bu atamaya eklenmeyen kullanıcıları listeler."></i>
          </label>
          <label class="uk-switch" for="show_only_unassigned_users">
            <input type="checkbox" id="show_only_unassigned_users" v-model="filter.show_only_unassigned_users" />
            <div class="uk-switch-slider"></div>
          </label>
        </div>
      </div>
      <div class="uk-flex uk-flex-right mt-3">
        <button class="btn btn-light mr-2" @click="resetFilter()">
          {{ $t('Sıfırla') }}
        </button>
        <a class="btn btn-primary grey" :href="'#' + componentId" @click="submitFilter"><i class="uil-filter"></i>
          {{ $t('general.filter') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/core/services'
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import company_module, {
  BASE_URL as COMPANY_BASE_URL,
  GET_ITEMS as GET_INSTITUTIONS,
  ITEMS as INSTITUTIONS,
  MODULE_NAME as COMPANY_MODULE,
} from "@/core/services/store/company.module";

import position_module, {
  BASE_URL as POSITION_BASE_URL,
  GET_ITEMS as GET_POSITIONS,
  ITEMS as POSITIONS,
  MODULE_NAME as POSITION_MODULE,
} from "@/core/services/store/position.module";

import title_module, {
  BASE_URL as TITLE_BASE_URL,
  GET_ITEMS as GET_TITLES,
  ITEMS as TITLES,
  MODULE_NAME as TITLE_MODULE,
} from "@/core/services/store/title.module";

import region_module, {
  BASE_URL as REGION_BASE_URL,
  GET_ITEMS as GET_REGIONS,
  ITEMS as REGIONS,
  MODULE_NAME as REGION_MODULE,
} from "@/core/services/store/region.module";

import department_module, {
  BASE_URL as DEPARTMENT_BASE_URL,
  GET_ITEMS as GET_DEPARTMENTS,
  ITEMS as DEPARTMENTS,
  MODULE_NAME as DEPARTMENT_MODULE,
} from "@/core/services/store/department.module";

import branch_module, {
  BASE_URL as BRANCH_BASE_URL,
  GET_ITEMS as GET_BRANCHES,
  ITEMS as BRANCHES,
  MODULE_NAME as BRANCH_MODULE,
} from "@/core/services/store/branch.module";

const _COMPANY_MODULE_NAME = COMPANY_MODULE;
const _POSITION_MODULE = POSITION_MODULE;
const _TITLE_MODULE = TITLE_MODULE;
const _REGION_MODULE = REGION_MODULE;
const _DEPARTMENT_MODULE = DEPARTMENT_MODULE;
const _BRANCH_MODULE = BRANCH_MODULE;

export default {
  name: "UserFilter",
  components: { MultipleSelectInput },
  props: {
    show_only_unassigned_users: false,
    reportAssignment: null,
    assignmentList: [],
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_COMPANY_MODULE_NAME, company_module)
    registerStoreModule(_POSITION_MODULE, position_module)
    registerStoreModule(_TITLE_MODULE, title_module)
    registerStoreModule(_REGION_MODULE, region_module)
    registerStoreModule(_DEPARTMENT_MODULE, department_module)
    registerStoreModule(_BRANCH_MODULE, branch_module)
  },
  data() {
    return {
      selectedDate: null,
      componentId: window.location.href.split('#/')[1],
      filter: {
        company_id: null,
        // branch_id: null,
        // department_id: null,
        // region_id: null,
        // title_id: null,
        // position_id: null,
        title_id: [],
        position_id: [],
        branch_id: [],
        assignment_id: [],
        region_id: [],
        department_id: [],
        show_only_unassigned_users: false,
      },
      assignment_id: [],
    }
  },
  computed: {
    branches: {
      get() {
        return store.getters[BRANCH_MODULE + "/" + BRANCHES];
      },
      set(value) {
      },
    },
    departments: {
      get() {
        return store.getters[DEPARTMENT_MODULE + "/" + DEPARTMENTS];
      },
      set(value) {
      },
    },
    regions: {
      get() {
        return store.getters[REGION_MODULE + "/" + REGIONS];
      },
      set(value) {
      },
    },
    titles: {
      get() {
        return store.getters[TITLE_MODULE + "/" + TITLES];
      },
      set(value) {
      },
    },
    positions: {
      get() {
        return store.getters[POSITION_MODULE + "/" + POSITIONS];
      },
      set(value) {
      },
    },
    institutions: {
      get() {
        return store.getters[COMPANY_MODULE + "/" + INSTITUTIONS];
      },
      set(value) {
      },
    },
    formattedBranch() {
      let results = [];
      if (this.branches === null || !this.branches instanceof Array) return [];

      this.branches.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedDepartment() {
      let results = [];
      if (this.departments === null || !this.departments instanceof Array) return [];

      this.departments.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedRegion() {
      let results = [];
      if (this.regions === null || !this.regions instanceof Array) return [];

      this.regions.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedInstitution() {
      let results = [];
      if (this.institutions === null || !Array.isArray(this.institutions)) return [];
      this.institutions.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
        });
      });
      return results;
    },
    formattedPosition() {
      let positions = [];
      if (this.positions === null || !this.positions instanceof Array) return [];

      this.positions.forEach((item) => {
        positions.push({
          id: item.id,
          name: item.name,
        });
      });
      return positions;
    },
    formattedTitle() {
      let titles = [];
      if (this.titles === null || !this.titles instanceof Array) return [];

      this.titles.forEach((item) => {
        titles.push({
          id: item.id,
          name: item.name,
        });
      });
      return titles;
    },
    userInfo: {
      get: function () {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set: function (newValue) {
      },
    },
    user_header: {
      get() {
        return JSON.parse(this.secureLocalStorage("user"));
      },
      set() { },
    },
  },
  methods: {
    changeCompany($event) {
      this.filter.company_id = $event;
      this.getValues();
    },
    submitFilter() {
      let filteredData = []
      filteredData = {
        company_id: this.filter.company_id ? this.filter.company_id.id : null,
        branch_id: this.filter.branch_id?.length > 0 ? this.filter.branch_id : null,
        department_id: this.filter.department_id?.length > 0 ? this.filter.department_id : null,
        region_id: this.filter.region_id?.length > 0 ? this.filter.region_id : null,
        title_id: this.filter.title_id?.length > 0 ? this.filter.title_id : null,
        position_id: this.filter.position_id?.length > 0 ? this.filter.position_id : null,
        show_only_unassigned_users: this.filter.show_only_unassigned_users ? this.filter.show_only_unassigned_users : false,
        assignment_id: this.assignment_id.length > 0 ? this.assignment_id : null,
        from_date: this.selectedDate?.start ? new Date(this.selectedDate?.start).toISOString().split('T')[0] : null,
        to_date: this.selectedDate?.end ? new Date(this.selectedDate?.end).toISOString().split('T')[0] : null
      }
      this.$emit('getFilter', filteredData)

    },
    resetFilter() {
      this.assignment_id = [];
      this.filter = {
        company_id: this.filter.company_id,
        branch_id: [],
        department_id: [],
        region_id: [],
        title_id: [],
        position_id: [],
        show_only_unassigned_users: false,
      }
    },
    getBranches() {
      this.$store.dispatch(BRANCH_MODULE + '/' + GET_BRANCHES, {
        url: BRANCH_BASE_URL,
        filters: {
          company_id: this.filter.company_id.id
        }
      });
    },
    getDepartments() {
      this.$store.dispatch(DEPARTMENT_MODULE + '/' + GET_DEPARTMENTS, {
        url: DEPARTMENT_BASE_URL,
        filters: {
          company_id: this.filter.company_id.id
        }
      });
    },
    getRegions() {
      this.$store.dispatch(REGION_MODULE + '/' + GET_REGIONS, {
        url: REGION_BASE_URL,
        filters: {
          company_id: this.filter.company_id.id
        }
      });
    },
    getTitles() {
      this.$store.dispatch(TITLE_MODULE + '/' + GET_TITLES, {
        url: TITLE_BASE_URL,
        filters: {
          company_id: this.filter.company_id.id
        }
      });
    },
    getPositions() {
      this.$store.dispatch(POSITION_MODULE + '/' + GET_POSITIONS, {
        url: POSITION_BASE_URL,
        filters: {
          company_id: this.filter.company_id.id
        }
      });
    },
    getInstitutions() {
      this.$store.dispatch(COMPANY_MODULE + '/' + GET_INSTITUTIONS, {
        url: COMPANY_BASE_URL,
        filters: {
          all: "all",
          sort: 'asc',
          order: 'id',
        }
      });
    },
    superAdmin() {
      if (this.user_header === null || !("roles" in this.user_header) || this.user_header.roles.length < 3) {
        return false;
      } else
        return true
    },
    getValues() {
      if (!this.filter.company_id) {
        this.filter.company_id = {
          id: this.userInfo.company_id, name: this.userInfo.company_name
        }
      }
      if (this.filter.company_id) {
        this.getPositions();
        this.getTitles();
        this.getRegions();
        this.getDepartments();
        this.getBranches();
        this.superAdmin() ? this.getInstitutions() : null
        this.filter.position_id = null;
        this.filter.title_id = null;
        this.filter.region_id = null;
        this.filter.department_id = null;
        this.filter.branch_id = null;
        this.filter.show_only_unassigned_users = false;

      }
    }
  },
  mounted() {
    this.getValues();
  }
}
</script>
<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
